export default function Gear({ fill = 'white', height = 32, onClick = () => {}, style = {}, width = 32 }) {
	return (
		<svg fill={fill} height={height} onClick={onClick} style={style} viewBox="0 0 31.493 31.492" width={width} x="0px" y="0px">
			<path d="M29.93,18.891c0.859,0,1.56-0.696,1.56-1.561v-3.176c0-0.861-0.697-1.56-1.56-1.56h-1.756c0-0.001,0-0.002-0.004-0.006
				c-0.307-1.215-0.789-2.361-1.418-3.41l1.223-1.225c0.607-0.609,0.607-1.596,0-2.205l-2.246-2.246
				c-0.293-0.293-0.69-0.457-1.104-0.457c-0.412,0-0.812,0.165-1.103,0.458l-1.223,1.223c-1.039-0.623-2.176-1.104-3.381-1.412
				c-0.004-0.001-0.012-0.903-0.02-1.765C18.895,0.692,18.2,0,17.34,0h-3.175c-0.416,0-0.814,0.166-1.107,0.461
				c-0.293,0.295-0.456,0.696-0.452,1.111l0.015,1.706c-1.244,0.264-2.417,0.8-3.491,1.442L7.915,3.502
				C7.623,3.209,7.226,3.045,6.812,3.045c-0.414,0-0.811,0.165-1.103,0.457L3.46,5.75c-0.608,0.609-0.609,1.596,0,2.205l1.223,1.223
				c-0.626,1.05-1.108,2.195-1.416,3.41c-0.001,0.004-0.001,0.005-0.003,0.006H1.562c-0.861,0-1.56,0.698-1.56,1.56v3.179
				c0,0.861,0.698,1.562,1.56,1.562h1.694c-0.015-0.062-0.027-0.119-0.042-0.184c0.015,0.059,0.027,0.118,0.042,0.176
				c0.308,1.231,0.793,2.394,1.429,3.455l-1.222,1.221c-0.293,0.291-0.457,0.688-0.457,1.104s0.164,0.811,0.457,1.104l2.247,2.248
				c0.292,0.291,0.688,0.457,1.102,0.457c0.413,0,0.811-0.166,1.103-0.457l1.221-1.223c1.071,0.641,2.245,1.131,3.488,1.438
				L12.61,29.92c-0.004,0.416,0.159,0.816,0.452,1.111s0.691,0.461,1.107,0.461h3.176c0.855,0,1.553-0.691,1.56-1.55
				c0.006-0.851,0.012-1.732,0.019-1.733c1.204-0.31,2.34-0.789,3.379-1.41l1.223,1.221c0.609,0.608,1.598,0.608,2.205,0l2.246-2.243
				c0.606-0.609,0.608-1.597,0-2.207l-1.224-1.224c0.638-1.062,1.121-2.224,1.433-3.455c0.016-0.059,0.016-0.055,0,0.008h1.748
				L29.93,18.891L29.93,18.891z M15.72,20.143c-2.421,0-4.384-1.965-4.384-4.385c0-2.419,1.963-4.385,4.384-4.385
				c2.421,0,4.384,1.964,4.384,4.385C20.104,18.18,18.141,20.143,15.72,20.143z"/>
		</svg>
	);
}
